import { useEffect, useState } from "react";
import { SocketFabric } from "shared/services/socket";
import { IUserModel } from "../../users";
import { access_token } from "entities/auth";
import { useStore } from "effector-react";
import { $individualChatMessageStore } from "../model";

const socket = new SocketFabric("wss://api.mama-api.ru/api/v1/chat/ws");

const useChatListWebSocket = () => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    socket.onOpen = () => {
      console.log("Соединение установлено.");
      setIsConnected(true);
    };

    /**
     * @description Это я тебе оставил, как пример типизации, приходит any, но ты можешь типизировать как тут
     * @type {IUserModel}
     * @param event
     */
    socket.onMessage = (event: IUserModel) => {
      console.log("Получены данные " + event);
    };

    // if (connected) socket.sendMessage("444");

    socket.onClose = (event) => {
      setIsConnected(false);
      if (event.wasClean) {
        console.log("Соединение закрыто чисто");
      } else {
        console.log("Обрыв соединения"); // например, "убит" процесс сервера
      }
      console.log("Код: " + event.code + " причина: " + event.reason);
    };

    socket.onError = (error) => {
      console.log("Ошибка " + error);
    };
  }, [isConnected]);

  const sendMessage = (
    message: string,
    chatId: string,
    typeChat: "solo" | "group",
    event: "message" | "join",
  ) => {
    {
      socket.sendMessage(
        JSON.stringify({
          event: event,
          type_chat: typeChat,
          data: {
            access_token: "Bearer " + access_token,
            message: message,
            chat_id: chatId,
          },
        }),
      );
    }
  };

  return { isConnected, sendMessage };
};

export { useChatListWebSocket };
