import React from "react";
import { UserStatusEnum } from "entities/auth";
import AvatarIcon from "shared/icons/avatar.svg";

const row = "flex justify-between items-center";
const rowText = "text-[14px] font-normal";

const getUserStatus = /* #__PURE__ */ (status: UserStatusEnum | string) => {
  switch (status) {
    case UserStatusEnum.ACTIVE:
      return "Активен";
    case UserStatusEnum.SUBSCRIBED:
      return "Подписка";
    case UserStatusEnum.NO_SUBSCRIBED:
      return "Без подписки";
    case UserStatusEnum.PROMO_CODE:
      return "Промокод";
    case UserStatusEnum.INACTIVE:
      return "Приостановлен";
    case UserStatusEnum.TRIAL:
      return "Пробный период";
    case UserStatusEnum.DELETED:
      return "Удален";
    case UserStatusEnum.BLOCKED:
      return "Заблокирован";
    case UserStatusEnum.UNREGISTERED:
      return "Не зарегистрирован";
    case "Не активирован":
      return "Не активирован";
    case "Активен":
      return "Активен";
    case "Использован":
      return "Использован";
    default:
      return "Не установлен";
  }
};

const getStatusBackgroundColor = /* #__PURE__ */ (status: string) => {
  switch (status) {
    case UserStatusEnum.ACTIVE:
      return "rgba(173, 240, 141, 0.4)";
    case "Активен":
      return "rgba(173, 240, 141, 0.4)";
    case UserStatusEnum.INACTIVE:
      return "rgba(242, 100, 100, 0.4)";
    case UserStatusEnum.SUBSCRIBED:
      return "rgba(173, 240, 141, 0.4)";
    case UserStatusEnum.PROMO_CODE:
      return "rgba(173, 240, 141, 0.4)";
    case UserStatusEnum.TRIAL:
      return "rgba(255, 223, 110, 0.4)";
    case UserStatusEnum.NO_SUBSCRIBED:
      return "rgba(255, 223, 110, 0.4)";
    case UserStatusEnum.DELETED:
      return "rgba(242, 100, 100, 0.4)";
    case "Использован":
      return "rgba(242, 100, 100, 0.4)";
    case UserStatusEnum.BLOCKED:
      return "rgba(242, 100, 100, 0.4)";
    default:
      return "rgba(255, 223, 110, 0.4)";
  }
};

const finalyValues = /* #__PURE__ */ (
  image: string | undefined,
  firstName: string,
  secondName: string,
  page: string | undefined,
  status: string,
  lastName: string | undefined,
) => {
  const avatarUser = image
    ? // это потом в константу вынести, и аргументом {image принимать}
      `https://api.mama-api.ru/api/v1/resources/avatar/${image}`
    : AvatarIcon;
  const nameUser = firstName
    ? `${firstName} ${secondName ? secondName : ""} ${lastName ? lastName : ""}`
    : "Имя не указано";

  const statusOptions =
    page === "twoStatus"
      ? [
          { value: UserStatusEnum.ACTIVE, label: "Активный" },
          { value: UserStatusEnum.INACTIVE, label: "Приостановлен" },
        ]
      : page === "promo"
        ? [
            { value: "Активен", label: "Активен" },
            { value: "Использован", label: "Использован" },
            { value: "Не активирован", label: "Не активирован" },
            { value: "Удалить", label: "Удалить" },
          ]
        : [
            { value: UserStatusEnum.SUBSCRIBED, label: "Подписка" },
            { value: UserStatusEnum.PROMO_CODE, label: "Промокод" },
            { value: UserStatusEnum.TRIAL, label: "Пробный период" },
            { value: UserStatusEnum.NO_SUBSCRIBED, label: "Без подписки" },
            { value: UserStatusEnum.DELETED, label: "Удален" },
          ];

  const statusBgc = getStatusBackgroundColor(status);

  return { avatarUser, nameUser, statusOptions, statusBgc };
};

export { getUserStatus, getStatusBackgroundColor, finalyValues, row, rowText };
