// ChatFeature.tsx
import React, { FC, useEffect, useRef, useState } from "react";
import { useEvent, useStore } from "effector-react";
import { v4 as uuidv4 } from "uuid";
// entities
import { ChatGroups } from "entities/chats/ui/chat-groups";
// shared
import { ChatGrop } from "shared/ui/chat-group";
import { ChatMessage } from "shared/ui/chat-message";
import { BaseInput } from "shared/ui/input/base/input";
import SearchInput from "shared/ui/input/base/search-input";
import { SettingsPanel } from "shared/ui/settings-panel";
import sendIcon from "shared/icons/chat/send.svg";
import sendActiveIcon from "shared/icons/chat/sendActive.svg";
// features
import { ChatModel } from "entities/chats";
import { BarLoader } from "react-spinners";
import { AccountModel } from "entities/account";
import { getAvatarResourcesUrl } from "shared/helpers/get-avatar-resources-url";
import { useChatListWebSocket } from "entities/chats/lib/hooks";
import { useForm } from "react-hook-form";
import { GroupChatGroups } from "entities/chats/ui/group-chat-groups";
import { Message } from "entities/chats/types";
import { addMessage } from "entities/chats/model";
import { IChatFeature } from "./type";
const socket = new WebSocket("wss://api.mama-api.ru/api/v1/chat/ws");

const currentDate = new Date();
const hours = ("0" + currentDate.getHours()).slice(-2);
const minutes = ("0" + currentDate.getMinutes()).slice(-2);
const currentFormattedDateTimeString = `${hours}:${minutes}`;
const uniqueId = uuidv4();

const ChatFeature: FC<IChatFeature> = ({ setVisible }) => {
  const { control } = useForm({
    defaultValues: {
      messageText: "",
    },
  });

  // info about selected chat
  const [inputValue, setInputValue] = useState("");
  const [currentChatTitle, setCurrentChatTitle] = useState("");
  const [currentChatId, setCurrentChatId] = useState("");
  const [currentChatAvatar, setCurrentChatAvatar] = useState("");
  const [currentChatIsIndividual, setCurrentChatIsIndividual] = useState(false);
  const [searchText, setSearchText] = useState("");

  const individualChatMessageStore = useStore(
    ChatModel.$individualChatMessageStore,
  );
  const getIndividualChatMessage = useEvent(
    ChatModel.getIndividualChatMessageFx,
  );
  const setCurrentSoloChat = useEvent(ChatModel.setCurrentSoloChatEv);
  const currentSoloChat = useStore(ChatModel.$currentSoloChat);
  const isLoading = useStore(ChatModel.getIndividualChatsListFx.pending);
  const individualChatsList = useStore(ChatModel.$individualChatsListStore);
  const groupChatsList = useStore(ChatModel.$groupChatsListStore);
  const accountId = useStore(AccountModel.$accountInfo)?.account.account.id;

  useEffect(() => {
    if (currentSoloChat && currentSoloChat.id_group_chat) {
      void getIndividualChatMessage({
        chat_id: currentSoloChat.id_group_chat,
        type_chat: "group",
      });
    } else if (currentSoloChat && currentSoloChat.id) {
      void getIndividualChatMessage({
        chat_id: currentSoloChat.id,
        type_chat: "solo",
      });
    }
  }, [currentSoloChat, getIndividualChatMessage]);

  const { sendMessage } = useChatListWebSocket();

  const messageListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messageListRef.current?.scrollIntoView({ block: "end" });
  });

  useEffect(() => {
    ChatModel.getIndividualChatsListFx();
    ChatModel.getGroupChatsListFx();
  }, []);

  useEffect(() => {
    if (currentSoloChat) {
      setCurrentChatTitle(
        currentSoloChat.id_group_chat
          ? //@ts-ignore
            currentSoloChat.group_chat?.name || ""
          : //@ts-ignore
            currentSoloChat.participant_2?.first_name || "",
      );

      setCurrentChatId(currentSoloChat.id_group_chat ?? "");

      setCurrentChatAvatar(
        //@ts-ignore
        currentSoloChat.group_chat?.avatar ||
          //@ts-ignore
          currentSoloChat.participant_2?.avatar ||
          "",
      );

      setCurrentChatIsIndividual(currentSoloChat.id_group_chat ? true : false);
    }
  }, [currentSoloChat]);

  useEffect(() => {
    console.log(currentSoloChat);
  }, [currentSoloChat]);

  // Функция для группировки сообщений по дате создания
  const groupMessagesByDate = (messages: Message[] | undefined) => {
    const groupedMessages: { [date: string]: Message[] } = {};
    messages?.forEach((message) => {
      const date = message.created_at.slice(0, 10); // Получаем только дату без времени
      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }
      groupedMessages[date].push(message);
    });
    return groupedMessages;
  };

  // Отрисовка сообщений
  const renderMessages = (messages: Message[]) => {
    return messages.map((message) => (
      <ChatMessage
        key={message.id}
        label={
          currentSoloChat && currentSoloChat.id_group_chat
            ? "partiant"
            : //@ts-ignore
              currentSoloChat.participant_2.profession
        }
        message={message.text}
        icon={getAvatarResourcesUrl(
          currentSoloChat && currentSoloChat.id_group_chat
            ? //@ts-ignore
              currentSoloChat.participant.avatar ?? ""
            : //@ts-ignore

              currentSoloChat.participant_1.avatar ?? "",
        )}
        isYoursMessage={message.sender_id === accountId}
        delivered
        time={message.created_at.slice(11, 16)}
      />
    ));
  };

  // Отрисовка разделителя даты
  const renderDateSeparator = (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthNames = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];
    const monthIndex = date.getMonth();
    const formattedDate = `${day} ${monthNames[monthIndex]}`;

    return (
      <div key={dateString} className="text-center my-2">
        {formattedDate === "NaN undefined" ? "" : formattedDate}
      </div>
    );
  };

  // Получаем отсортированные и сгруппированные сообщения по дате создания
  const groupedMessages = groupMessagesByDate(
    individualChatMessageStore?.messages.slice().reverse(),
  );

  // отправка сообщения
  const handleSendMessage = () => {
    if (currentSoloChat) {
      if (currentSoloChat.id_group_chat) {
        sendMessage(
          inputValue,
          currentSoloChat.id_group_chat,
          "group",
          "message",
        );
      } else {
        console.log("Send solo mess");
        sendMessage(inputValue, currentSoloChat.id, "solo", "message");
      }

      setInputValue("");

      const newMessage = {
        id: uniqueId,
        chat_id: currentSoloChat.id,
        sender_id: accountId ?? "",
        text: inputValue,
        created_at: currentFormattedDateTimeString,
      };
      addMessage(newMessage);
    }
  };

  /**
   * Поиск по отрисованным сообщениям
   */

  // Функция для фильтрации сообщений по тексту
  const filterMessages = (messages: Message[], searchText: string) => {
    return messages.filter((message) =>
      message.text.toLowerCase().includes(searchText.toLowerCase()),
    );
  };

  // Обработчик изменений в поле поиска
  const handleSearchInputChange = (value: string) => {
    setSearchText(value);
  };

  // Отрисовка отфильтрованных сообщений
  const filteredMessages = filterMessages(
    individualChatMessageStore?.messages.slice().reverse() || [],
    searchText,
  );

  return (
    <div className="fixed top-0 left-0 w-full h-full overflow-auto bg-white bg-opacity-10 backdrop-filter backdrop-blur-[3px] flex justify-end items-start z-10 p-8">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          socket.send("sdfds");
        }}
        className="shadow-sm flex flex-row gap-[4px] bg-[#F5F8FE] p-[4px] w-[700px] h-full rounded-[8px]"
      >
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <BarLoader color="#36D7B7" width={"40%"} loading />
          </div>
        ) : (
          <>
            <div className="flex flex-col w-[300px]">
              <SearchInput
                placeholder="Поиск"
                onInputChange={handleSearchInputChange}
              />
              <GroupChatGroups
                title="Групповые чаты"
                groups={groupChatsList?.chats.map((chat) => (
                  <ChatGrop
                    onClick={() => {
                      setCurrentSoloChat(chat);
                    }}
                    title={chat.group_chat.name || "---"}
                    subTitle={chat.last_message?.text || "Напишите что-нибудь"}
                    img={getAvatarResourcesUrl(chat.group_chat.avatar)}
                    countNewMessage={chat.unread_count}
                    sender={
                      chat.last_message
                        ? chat.last_message.sender_id === accountId
                          ? "Вы"
                          : `${chat.participant.first_name}:`
                        : ""
                    }
                  />
                ))}
              />
              <ChatGroups
                title="Чаты"
                groups={individualChatsList?.chats.map((chat) => (
                  <ChatGrop
                    onClick={() => setCurrentSoloChat(chat)}
                    title={chat.participant_2.first_name || "---"}
                    subTitle={chat.last_message?.text || "Напишите что-нибудь"}
                    img={getAvatarResourcesUrl(chat.participant_2.avatar)}
                    countNewMessage={chat.unread_count}
                    sender={
                      chat.last_message
                        ? chat.last_message.sender_id === accountId
                          ? "Вы"
                          : `${chat.participant_2.first_name}:`
                        : ""
                    }
                  />
                ))}
              />
            </div>

            <div className="flex flex-col w-[427px]">
              <SettingsPanel
                title={currentChatTitle}
                setVisible={setVisible}
                chatId={currentChatId}
                chatAvatar={currentChatAvatar}
                isIndividualChat={currentChatIsIndividual}
              />
              <div
                className="overflow-auto px-[12px]"
                style={{ height: "calc(100vh - 100px)" }}
              >
                {/*Отображение сообщений с учетом фильтрации*/}
                {Object.entries(groupMessagesByDate(filteredMessages)).map(
                  ([date, messages]) => (
                    <React.Fragment key={date}>
                      {renderDateSeparator(date)}
                      {renderMessages(messages)}
                    </React.Fragment>
                  ),
                )}
                <div ref={messageListRef} />
              </div>
              <BaseInput
                name={"messageText"}
                placeholder="Написать сообщение"
                renderRightIcon={inputValue === "" ? sendIcon : sendActiveIcon}
                control={control}
                onInputChange={() => {}}
                value={inputValue}
                onClickRightIcon={handleSendMessage}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export { ChatFeature };
