import React, { useEffect, useState } from "react";
// styles
import "./styles.css";
// features
import { ChatFeature } from "features/chats";
// entities
import { userLogoutEvent } from "entities/auth";
// shared
import PowerIcon from "shared/icons/power.svg";
import AvatarIcon from "shared/icons/avatar.svg";
import ChatIcon from "shared/icons/chat.svg";
// effector
import { useEvent, useStore } from "effector-react";
import {
  $currentSongPlay,
  changeCurrentSongPlayingStatusEvent,
} from "entities/audio/model";
import { __BASE_URL__ } from "shared/services/http";
import { getMyProfileFx, setModeratorProfileMe } from "entities/users";
import { AccountModel } from "entities/account";
// helpers
import { finalValues } from "../lib/helpers";

const HeaderWidget = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onClick = useEvent(userLogoutEvent);

  const boxButton =
    "border-gray-eb border-[1px] p-[8.81px] rounded-lg cursor-pointer flex gap-1.5";
  const currentSongPlay = useStore($currentSongPlay);
  const changeCurrentSongPlayingStatus = useEvent(
    changeCurrentSongPlayingStatusEvent
  );
  const getMyProfile = useEvent(getMyProfileFx);

  useEffect(() => {
    void getMyProfile();
  }, [getMyProfile]);

  const accountInfo = useStore(AccountModel.$accountInfo);

  const [userRole, fullName] = finalValues(
    accountInfo?.account.account.role,
    accountInfo?.account.account.first_name,
    accountInfo?.account.account.second_name,
    accountInfo?.account.account.last_name,
    accountInfo?.account.online_school.name
  );

  const img = accountInfo?.account.account.avatar
    ? `${__BASE_URL__}/resources/avatar/${accountInfo?.account.account.avatar}`
    : AvatarIcon;

  return (
    <div
      className={
        "h-[70px] min-h-[70px] px-8 flex flex-row items-center justify-between"
      }
    >
      <div className={"flex flex-row items-center justify-center"}>
        <div
          className={
            "flex flex-row items-center justify-center cursor-pointer mr-8"
          }
        >
          <>
            <div className="w-[40px] h-[40px] rounded-full mr-4 overflow-hidden flex">
              <img
                width={40}
                height={40}
                className={"w-full h-full object-cover"}
                src={img}
                alt={"AvatarIcon"}
              />
            </div>

            <div className={"flex flex-col"}>
              <span className={"text-sm font-semibold"}>
                {fullName.trim() === "" ? "имя не указано" : fullName}
              </span>
              <span className={"text-xs opacity-40"}>{userRole}</span>
            </div>
          </>
        </div>
        {currentSongPlay ? (
          <audio
            onPause={() => changeCurrentSongPlayingStatus(false)}
            onPlay={() => changeCurrentSongPlayingStatus(true)}
            style={{ marginLeft: 8, height: 48 }}
            autoPlay
            controls
            src={__BASE_URL__ + "/music/" + currentSongPlay}
          />
        ) : null}
      </div>

      <div className="flex flex-row gap-5">
        <div
          className={`${boxButton} px-[20px] `}
          onClick={() => setIsVisible(true)}
        >
          <img src={ChatIcon} alt="ChatIcon" />
          <span className="text-sm">Чаты</span>
        </div>
        <div
          className={boxButton}
          onClick={() => {
            setModeratorProfileMe();
            localStorage.clear();
            onClick();
          }}
        >
          <img src={PowerIcon} alt="PowerIcon" />
        </div>
      </div>
      {isVisible && <ChatFeature setVisible={setIsVisible} />}
    </div>
  );
};

export { HeaderWidget };
